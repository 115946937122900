import {defineStore, StoreDefinition} from 'pinia';
import {User} from "@/types";

// change to environment var
const api  =  process.env.VUE_APP_API_AUTH;

type AuthState = {
    user: User | undefined;
    loading: boolean;
    isAuthenticated: boolean;
    error: string | unknown;
}

export const useAuthStore: StoreDefinition = defineStore('user',{
    state: (): AuthState =>{
        return {
            user: undefined,
            loading: false,
            isAuthenticated: false,
            error: undefined
        }
    },
    getters: {
        getUserById: (state: AuthState) => {
            return() => {
                if(state.user !== undefined){
                    return state.user.id;
                }
            }
        }
    },
    actions: {
        async login(formData){

            try {
                this.loading = true
                // Fetch the CSRF cookie
                const csrfResponse = await fetch(`${api}/sanctum/csrf-cookie`, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!csrfResponse.ok) {
                    throw new Error('Failed to fetch CSRF cookie');
                }

                // Proceed with the login request
                const loginResponse = await fetch(`${api}/api/login`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Referer': 'http://localhost'
                    },
                    body: JSON.stringify(formData)
                });

                const loginData = await loginResponse.json();

                if (!loginResponse.ok) {
                    throw new Error(loginData.message || 'Login failed');
                }
                this.isAuthenticated = true;
                localStorage.setItem('isAuthenticated', JSON.stringify(true))
                console.log('Login successful:', loginData);
            } catch (e) {
                this.error = e instanceof Error ? e.message : String(e);
                console.error('Error during login:', e);
            } finally{
                this.loading = false;

            }
        },
        async getUser(){
            try {
                const userResponse = await fetch(`${api}/api/user`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });

                if(! userResponse.ok){
                    localStorage.removeItem("isAuthenticated");
                    throw new Error ('Failed to fetch User');
                }

                this.user = await userResponse.json();

                this.isAuthenticated = true;
                return this.user;

            }catch(e){

                console.log(e);
            }
        },
        async register(formData){
            try {
                this.loading = true
                // Fetch the CSRF cookie
                const csrfResponse = await fetch(`${api}/sanctum/csrf-cookie`, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!csrfResponse.ok) {
                    throw new Error('Failed to fetch CSRF cookie');
                }

                // Proceed with the login request
                const registerResponse = await fetch(`${api}/api/register`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Referer': 'http://localhost'
                    },
                    body: JSON.stringify(formData)
                });

                const registerData = await registerResponse.json();

                if (!registerResponse.ok) {
                    throw new Error(registerData.message || 'Login failed');
                }

                console.log('Register successful:', registerData);
            } catch (e) {
                this.error = e instanceof Error ? e.message : String(e);
                console.error('Error during register:', e);
            } finally{
                localStorage.setItem('isAuthenticated', JSON.stringify(true))
                this.loading = false;
            }
        },
        async logout(){
            try{
                await fetch(`${api}/api/logout`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Referer': 'http://localhost'
                    }
                })
            }
          catch(e){
                console.log("Error Logging Out", e)
          }finally{
                localStorage.setItem('isAuthenticated', JSON.stringify(false));
                this.user = undefined;
            }
        }
    }


})