import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
    menuController
} from "@ionic/vue";
import {computed, onMounted, Ref, ref, watch, watchEffect} from "vue";

import {useAuthStore} from "@/store/auth";
import {User} from "@/types";
import router from "@/router";
type AppPage = {
  title: string;
  url: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const authStore = useAuthStore();

const user: Ref<User | undefined> = ref();
const selectedIndex = ref(0);
// const appPages = computed(() => {
//   if (user.value) {
//     return [
//       {
//         title: "View My Appraisals",
//         url: "/",
//       },
//       {
//         title: "Edit Profile",
//         url: `/profile/edit/${user.value.id}`, // Access user id here safely
//       },
//       {
//         title: "Change Password",
//         url: "/profile",
//       },
//     ];
//   }
//   return [];
// });
const updateAppPages = () => {
  if (user.value) {
    appPages.value = [
      {
        title: "View My Appraisals",
        url: "/",
      },
      {
        title: "Edit Profile",
        url: `/profile/edit/${user.value.id}`,
      },
      {
        title: "Change Password",
        url: `/auth/change-password`,
      },
    ];
  } else {
    appPages.value = [];
  }
};

const appPages = ref([] as AppPage[]);

watchEffect(
    () => {

      user.value = authStore.user;
      updateAppPages();
    }
);

// const path = window.location.pathname.split("folder/")[1];
// if (path !== undefined) {
//   selectedIndex.value = appPages.value.findIndex(
//     (page) => page.title.toLowerCase() === path.toLowerCase()
//   );
// }

const logOut =  async () => {
  try{
    await authStore.logout()
    await router.push('/auth/login')
    await menuController.close("main-menu")
  }catch(e){
    console.log(e)
  }

}



onMounted(async ()=>{
      if(authStore.user == undefined){
        await authStore.getUser();

      }
      user.value = authStore.user
    updateAppPages();
    console.log(appPages.value)
    }
)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonSplitPane), { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonMenu), {
            "menu-id": "main-menu",
            "content-id": "main-content",
            type: "overlay"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonContent), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonList), { id: "inbox-list" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonListHeader), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(user.value?.first_name), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        lines: "none",
                        class: "hydrated"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("Account Details")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(appPages.value, (p, i) => {
                        return (_openBlock(), _createBlock(_unref(IonMenuToggle), { key: i }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonItem), {
                              onClick: ($event: any) => (selectedIndex.value = i),
                              "router-direction": "root",
                              "router-link": p.url,
                              lines: "none",
                              class: _normalizeClass(["hydrated", { selected: selectedIndex.value === i }])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "router-link", "class"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { id: "labels-list" }, {
                    default: _withCtx(() => [
                      (user.value)
                        ? (_openBlock(), _createBlock(_unref(IonItem), {
                            key: 0,
                            lines: "none",
                            onClick: logOut
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("Log Out")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_unref(IonItem), {
                              class: "menu__button",
                              lines: "none",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'Login' })))
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" Login ")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              class: "menu__button",
                              lines: "none",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({name: 'Register'})))
                            }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode(" Sign-Up ")
                              ])),
                              _: 1
                            })
                          ]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_openBlock(), _createBlock(_unref(IonRouterOutlet), {
            key: _ctx.$route.fullPath,
            id: "main-content"
          }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})