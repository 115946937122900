<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu menu-id="main-menu" content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>{{ user?.first_name}}</ion-list-header>
<!--            <ion-note>zach@zt.com</ion-note>-->
            <ion-item

                lines="none"

                class="hydrated"

            >

              <ion-label>Account Details</ion-label>
            </ion-item>
            <ion-menu-toggle

              v-for="(p, i) in appPages"
              :key="i"
            >
              <ion-item
                @click="selectedIndex = i"
                router-direction="root"
                :router-link="p.url"
                lines="none"

                class="hydrated"
                :class="{ selected: selectedIndex === i }"
              >

                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-list id="labels-list">


            <ion-item
                v-if="user"
              lines="none"
              @click="logOut"
            >

              <ion-label >Log Out</ion-label>
            </ion-item>
            <div   v-else>
              <ion-item
                class="menu__button"
                  lines="none"
                  @click="router.push({ name: 'Login' })"
              >
                Login
              </ion-item>

              <ion-item
                  class="menu__button"
                  lines="none"
                 @click="router.push({name: 'Register'})"
              >

              Sign-Up
              </ion-item>
            </div>

          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet :key="$route.fullPath" id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>

</template>
<script setup lang="ts">
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
    menuController
} from "@ionic/vue";
import {computed, onMounted, Ref, ref, watch, watchEffect} from "vue";

import {useAuthStore} from "@/store/auth";
import {User} from "@/types";
import router from "@/router";
const authStore = useAuthStore();

const user: Ref<User | undefined> = ref();
const selectedIndex = ref(0);
// const appPages = computed(() => {
//   if (user.value) {
//     return [
//       {
//         title: "View My Appraisals",
//         url: "/",
//       },
//       {
//         title: "Edit Profile",
//         url: `/profile/edit/${user.value.id}`, // Access user id here safely
//       },
//       {
//         title: "Change Password",
//         url: "/profile",
//       },
//     ];
//   }
//   return [];
// });
const updateAppPages = () => {
  if (user.value) {
    appPages.value = [
      {
        title: "View My Appraisals",
        url: "/",
      },
      {
        title: "Edit Profile",
        url: `/profile/edit/${user.value.id}`,
      },
      {
        title: "Change Password",
        url: `/auth/change-password`,
      },
    ];
  } else {
    appPages.value = [];
  }
};

type AppPage = {
  title: string;
  url: string
}
const appPages = ref([] as AppPage[]);

watchEffect(
    () => {

      user.value = authStore.user;
      updateAppPages();
    }
);

// const path = window.location.pathname.split("folder/")[1];
// if (path !== undefined) {
//   selectedIndex.value = appPages.value.findIndex(
//     (page) => page.title.toLowerCase() === path.toLowerCase()
//   );
// }

const logOut =  async () => {
  try{
    await authStore.logout()
    await router.push('/auth/login')
    await menuController.close("main-menu")
  }catch(e){
    console.log(e)
  }

}



onMounted(async ()=>{
      if(authStore.user == undefined){
        await authStore.getUser();

      }
      user.value = authStore.user
    updateAppPages();
    console.log(appPages.value)
    }
)

</script>

<style scoped>
ion-app {
  --background: black;
}
ion-menu ion-content {
  --background: url('/public/assets/backgrounds/menu_bg.jpg') no-repeat center center/cover;
}
ion-menu::part(container){
  width: 100%;
}
ion-list-header{
  margin-bottom: 30px;
  color: #fff;
}
ion-menu ion-item{
  --cursor: pointer;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}
ion-menu ion-list{
  background: none;
}


ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb));
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;

}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

 ion-item{
  --background: var(--secondary-blue);
   color: var(--ion-color-light);
   margin-bottom: 10px;
}
</style>
