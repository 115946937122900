import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Index from "@/views/properties/Show.vue";
import {useAuthStore} from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("../views/HomePage.vue"),
    name: "Home"


  },
  {
    path: "/properties/:id", component: () => import("../views/properties/Show.vue"),
    name: 'PropertyShow'
  },
  {
    path: "/properties/create", component: () => import("../views/properties/Create.vue"),
    name: 'PropertyCreate'
  },
  {
    path: "/properties/:id/share", component: () => import("../views/properties/Share.vue"),
    name: 'PropertyShare'
  },
  // {
  //   path: "/properties/pdf", component: () => import("../views/properties/PDF.vue")
  // },
  {
    path: "/profile/edit/:id", component: () => import("../views/profile/Edit.vue")
  },

  {
    name: "Login",
    path: "/auth/login", component: () => import("../views/auth/Login.vue")
  },
  {
    name: "Register",
    path: "/auth/register", component: () => import("../views/auth/Register.vue")
  },
  {
    name: "ChangePassword",
    path: "/auth/change-password", component: () => import("../views/auth/ChangePassword.vue"),

  },
  {
    name: "ForgotPassword",
    path: "/auth/forgot-password", component: () => import("../views/auth/ForgotPassword.vue")
  },
  {
    name: "PasswordReset",
    path: "/password-reset/:token",
    component: () => import("../views/auth/PasswordReset.vue"),
    props: (route) => ({ token: route.params.token, email: route.query.email })
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthStore();
  await store.getUser();
  if(store.user == undefined){
    localStorage.setItem('isAuthenticated', 'false')
    console.log(localStorage.getItem('isAuthenticated'), store.user)
  } else{
    console.log(localStorage.getItem('isAuthenticated'))
    localStorage.setItem('isAuthenticated', 'true')
  }
  const isAuth = JSON.parse(localStorage.getItem('isAuthenticated') || 'false');

  console.log("Is Auth:", isAuth);

  // Allow access to the Login and Register routes if the user is not authenticated
  if ((to.name === 'Login' || to.name === 'Register' || to.name === 'ForgotPassword' || to.name === 'PasswordReset') && !isAuth) {
    next();
  }
  // Redirect to Login if user is not authenticated and trying to access other routes
  else if (to.name !== 'Login' && to.name !== 'Register' && !isAuth) {
    next({ name: 'Login' });
  }
  // Redirect to home if user is authenticated and trying to access Login or Register routes
  else if ((to.name === 'Login' || to.name === 'Register') && isAuth) {
    next('/');
  }
  // Allow access to other routes if authenticated
  else {
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   const store = useAuthStore()
//
//   const isAuth = JSON.parse(localStorage.getItem('isAuthenticated') || 'false') ;
//   console.log("Is Auth:", isAuth)
//
//
//   if (to.name !== 'Login' && isAuth == false) {
//
//     next({ name: 'Login' })
//   }  else if( to.name == 'Login' && isAuth || to.name == 'Register' && isAuth) {
//     next('/')
//   }else {
//     next()
//   }
// })

export default router;
